@import "core/index.scss";
.pricebox {
	@include font-medium;
	display: inline-block;
	font-size: 1.8rem;

	&.big {
		font-size: 2.4rem;
		.top-price {
			@include font-semibold;
		}

		.pricebox-old {
			font-size: .66em;
		}

		.top-discount {
			margin-left: .5em;
		}
	}

	&.align-right {
		text-align: right;

		.pricebox-top {
			justify-content: flex-end;
		}
	}

	&.strike {
		.top-price {
			text-decoration: line-through;
		}
	}

	.pricebox-top {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		align-items: flex-end;
		
		> * {
			font-size: .8em;
		}

		> * + * {
			margin-left: .3em;
		}
	}

		.top-price {
			font-size: 1em;
			letter-spacing: -.025em;
		}

		.top-discount {
			margin-bottom: .1em;
			font-size: .66em;
		}

	.pricebox-old {
		display: block;
		font-size: .83em;
		text-decoration: line-through;
		margin-bottom: .5em;
		opacity: .5;

		@include media {
			margin-top: .2em;
		}
	}
}