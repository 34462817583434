@import "core/index.scss";
.section.site-features {
	padding: 4.8rem 0;

	@include media {
		padding: 1rem 0;
		background: $color-bg;
	}

	.features-wrap {
		@include media {
			max-width: 100%;
			width: 100%;
		}
	}
	
	.features-list {
		display: flex;
		justify-content: center;
		border-bottom-width: .3rem;

		@include media {
			display: flex;
			// white-space: nowrap;
			// overflow-x: scroll;
			// max-width: 100%;
			justify-content: flex-start;
			padding: 0 1rem;
			flex-wrap: wrap;
		}
	}

		.list-feature {
			@include font-medium;
			text-align: center;
			color: $color-text-blue;
			width: 15rem;

			@include media {
				width: 25%;
				// display: inline-block;
				flex: none;
				vertical-align: top;
				padding: 2rem .2rem;
			}
		}

			.feature-icon {
				margin: 0 auto 1.2rem;
				width: 4.7rem;
				height: 4.7rem;
				display: flex;
				align-items: center;
				justify-content: center;

				@include media {
					width: 4rem;
					height: 4rem;
					margin-bottom: .6rem;
				}
			}

			.feature-text {
				width: 100%;
				font-size: 1.5rem;
				line-height: 1.8rem;
				letter-spacing: .025em;

				@include media {
					color: $color-text;
					font-size: 1rem;
					line-height: 1.5rem;
				}

				
			}
}