@import "core/index.scss";
.section {
	&.detail-info {
		.info-wrap {
			display: flex;
			flex-flow: row wrap;
			align-items: flex-start;

			@include media {
				display: block;
			}
		}

		.info-left {
			flex: 1 0 0;
			width: calc(49.2% - 2rem);

			@include media {
				width: 100%;
				position: relative;
			}
			
			.left-controls {
				position: absolute;
				right: 0;
				top: 0;
				z-index: 2;
				display: flex;
				flex-direction: column;

				> * + * {
					margin-top: 1rem;
				}
			}

			.left-gallery {
				display: flex;
				flex-flow: row wrap;

				@include media {
					display: block;
				}
			}

				.gallery-bottomlabels {

					> * {
						margin: 0 0 .5rem;

						&:not(:last-child) {
							margin-right: .5rem;
						}
					}

					// .bottomlabels-campaign {}

						.campaign-infotext {
							@include font-medium;
							color: $color-pink-darker;
							margin-top: .2em;
							display: block;
						}

					+ .gallery-thumbswrap {
						margin-top: 1.5rem;
					}
				}

				.gallery-thumbswrap {
					width: 6.5rem;
					margin-right: 2rem;
					order: 1;

					@include media {
						width: 100%;
						margin-top: 1.5rem;
					}
				}

				.gallery-thumbs {
					height: 37rem;
					margin-top: -.5rem;

					@include media {
						height: auto;
						margin: 0 -.5rem;
						width: calc(100% + 1rem);
						height: 4.8rem;

						&.placeholder {
							display: flex;
						}
					}

					.swiper {
						@include mediaMin {
							height: 100%;
						}
					}

					.swiper-slide {
						padding: .5rem 0;

						@include media {
							padding: 0 .5rem;

							&.placeholder {
								width: 16.66666666%;
								width: calc(100% / 6);
							}
						}

						&.placeholder {
							height: auto;
						}
					}

					.swiper-button-prev,
					.swiper-button-next {
						&:before {
							@include icon('angle-up');
						}
					}

					@include mediaMin {
						.swiper-button-prev,
						.swiper-button-next {
							left: 0;
							right: 0;
							width: auto;
							transform: none;
							border-radius: 0;
							box-shadow: none;
							height: 4rem;
							font-size: 1.2rem;
	
							background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 47%, rgba(255,255,255,0) 100%);
							background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 47%,rgba(255,255,255,0) 100%);
							background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 47%,rgba(255,255,255,0) 100%);
							filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );
	
							&.swiper-button-disabled {
								opacity: 0!important;
								pointer-events: none;
							}
	
							@include mediaMin {
								&:hover {
									color: $color-pink;
								}
							}
						}
	
						.swiper-button-prev {
							top: 0;
						}
	
						.swiper-button-next {
							top: auto;
							bottom: 0;
	
							transform: rotate(180deg);
						}
					}

					// Loading State
					&.sliderelem.loading {
						.swiper-wrapper {
							display: block;
						}

						.swiper-slide {
							width: 100%!important;
						}
					}
				}

					.thumbs-thumb {
						width: 100%;
						border: .1rem solid $color-gray;
						border-radius: $radius-general;
						padding: .5rem;
						transition: border-color $transition-hover ease;

						&.active {
							border-color: $color-text;
						}
					}

						.thumb-imagewrap {
							position: relative;
							padding-bottom: 100%;
						}

						.thumb-image {
							width: 100%;
							height: 100%;
							top: 0;
							left: 0;
							position: absolute;

							&.video {
								&:before {
									@include icon('play');
									position: absolute;
									color: $color-white;
									text-shadow: $shadow-general;
									font-size: 2rem;
									top: 50%;
									left: 50%;
									transform: translate3d(-50%, -50%, 0);
									z-index: 2;
									opacity: .9;
								}
							}

							@include media {
								height: auto;
								padding-bottom: 100%;
							}
						}
				
				.gallery-mainslider {
					flex: 1 0 0;
					order: 2;
					width: calc(100% - 8.5rem);

					@include media {
						width: 100%;
					}
					
					.swiper-button-prev,
					.swiper-button-next {
						opacity: .8;

						&:not(.swiper-button-disabled) {
							&:hover {
								opacity: 1;
							}
						}
					}

					.swiper-button-prev {
						left: 1rem;
					}

					.swiper-button-next {
						right: 1rem;
					}
				}	
				
					.mainslider-imagewrap {
						position: relative;
						width: 100%;
						padding-bottom: 100%;
						border-radius: $radius-general;
						display: flex;
						align-items: center;
						justify-content: center;
						overflow: hidden;
						cursor: zoom-in;

						&.empty {
							cursor: default;
						}
						
						// border: .1rem solid $color-gray;
						// &.placeholder {
						// 	padding-bottom: 100%;
						// }

						&.is-video {
							&:before {
								@include icon('play');
								position: absolute;
								color: $color-white;
								text-shadow: $shadow-general;
								font-size: 5rem;
								top: 50%;
								left: 50%;
								transform: translate3d(-50%, -50%, 0);
								transition: opacity $transition-hover ease, transform $transition-hover ease;
								z-index: 2;
								opacity: .8;
							}

							&:hover {
								&:before {
									opacity: 1;
									transform: translate3d(-50%, -50%, 0) scale(1.1);
								}
							}
						}
					}

						.imagewrap-image {
							position: absolute;
							width: 100%;
							height: 100%;
							top: 0;
							left: 0;
						}

			.left-featuredcomment {
				margin-left: 8.5rem;
				margin: 4rem 0 0 8.5rem;
			}
		}

		.info-right {
			width: 50.8%;
			margin-left: 2rem;

			@include media {
				width: auto;
				margin: 2.5rem 0 0;
				border: none;
				box-shadow: none;
				padding: 0;
			}

			.right-supportnotification {
				@include font-medium;
				border: .1rem solid $color-text-light;
				background-color: $color-bg;
				padding: 1.5rem 1.5rem 1.3rem;
				border-radius: $radius-general;
				margin-bottom: 1.5rem;
			}

			.right-title {
				@include font-bold;
				font-size: 2rem;
				margin-bottom: 1rem;
				line-height: 1.5em;

				&.placeholder {
					height: 2rem;
					width: 70%;
				}
			}

			.right-brandlink {
				color: $color-text-lighter;
				text-decoration: underline;

				&.placeholder {
					width: 10rem;
					height: 1.5rem;
				}
			}

			.right-badgebar {
				padding: 2rem 0;
				display: flex;
				justify-content: space-between;
				align-items: center;

				@include media {
					flex-wrap: wrap;

					> * {
						margin: .3rem 0;
					}
				}
			}

				.badgebar-labels {
					
					@include mediaMin {
						max-width: calc(100% - 16.5rem);
					}

					.placeholder {
						width: 8rem;
						height: 1.6rem;
						display: inline-block;

						+ .placeholder {
							margin-left: .5rem;
						}
					}

					.label {
						+ .label {
							margin-left: 0;
						}
					}

					> * {
						margin-right: .5em;
						margin-bottom: .5rem;
					}
				}

				.badgebar-rating {
					@include font-semibold;
					display: flex;
					align-items: center;
					font-size: 1.4rem;
					color: $color-text-light;
				}

					.rating-stars {
						margin-right: .8rem;
						margin-bottom: .1rem;

						&.placeholder {
							width: 6.7rem;
							height: 1.1rem;
						}
					}

					.rating-text {
						width: 7.6rem;
						height: 1.4rem;
					}
					
			.right-pricebox {
				&.placeholder {
					width: 17.5rem;
					height: 5.2rem;
				}
			}

			.right-bottomlabels {
				margin-top: 1rem;

				> *{
					vertical-align: top;
					&:not(:last-child) {
						margin-right: .5rem;
					}
				}

				// .bottomlabels-campaign {}
		
					.campaign-infotext {
						@include font-medium;
						color: $color-pink-darker;
						margin-top: .2em;
						display: block;
					}
			}


			.right-installmentinfo {
				margin-top: 1.5rem;
				border-radius: $radius-general;

				button {
					text-decoration: underline;
				}

				&.placeholder {
					height: 1.5rem;
					width: 14rem;
				}
			}

				.installmentinfo-wrap {
					display: inline-block;
					background-color: $color-blue-bg;
					padding: 1.5rem;
				}

			.right-seperator {
				border-color: $color-gray;
				margin: 2rem 0;
			}

			// .right-opts {}

				.opts-head {
					display: flex;
					justify-content: space-between;
					margin-bottom: .5rem;
				}

				.opts-title {
					@include font-semibold;

					&.placeholder {
						width: 12.6rem;
						height: 2.4rem;
					}
				}

				.opts-btn {
					&.placeholder {
						width: 12rem;
						height: 2.4rem;
					}
				}

				.opts-list {
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-start;
					width: calc(100% + 1rem);
					margin-right: -1rem;
					margin-bottom: -.5rem;

					.placeholder {
						width: 7rem;
						height: 5.2rem;
					}
				}

					.list-opt {
						padding: .5rem 1rem .5rem 0;
						display: flex;
					}
					
						.opt-btn {
							@include font-semibold;
							min-height: 5rem;
							flex: 1 0 0;
							border: 0.1rem solid $color-gray;
							border-radius: 0.3rem;
							display: flex;
							flex-direction: column;
							align-items: center;
							font-size: 1.4rem;
							justify-content: center;
							line-height: 1.2em;
							flex-wrap: wrap;
							padding: 0.5rem 1.9rem 0.5rem 1.9rem;
							transition: color 300ms ease,background-color 300ms ease;

							&.kind {
								flex-direction: row;
								justify-content: initial;
							}

							&.active {
								background-color: $color-blue-bg;
								color: $color-blue;
							}
						}

						.opt-image {
							width: 3.2rem;
							height: 3.2rem;
							background-color: $color-white;
							border-radius: $radius-general;
							margin-right: .9rem;
							margin-left: -1.2rem;
						}

							// .opt-textwrap {}

							.textwrap-title {
								opacity: .5;
							}

							.textwrap-price {
								@include font-bold;
								margin-top: .2rem;
								font-size: 1.2rem;

								&.inactive {
									@include font-medium;
									font-size: .9rem;
									color: $color-text-light;
								}
							}

						.opt-subtitle {
							@include font-bold;
							font-size: 1rem;
						}

			.right-cargodate {
				@include font-regular;
				margin-top: 2rem;

				&.placeholder {
					height: 1.6rem;
					width: 80%;
				}
			}

			.right-addbtn {
				margin-top: 2rem;

				&.placeholder {
					height: 6rem;
				}
			}

			.right-features {
				display: flex;
				flex-flow: row wrap;
				width: calc(100% + 1rem);
				margin: 1.5rem -.5rem -.5rem;
			}

				.features-feature {
					@include font-semibold;
					position: relative;
					margin: .5rem;
					background-color: $color-blue-bg;
					color: $color-blue;
					display: flex;
					align-items: center;
					height: 6rem;
					padding: 0 1.8rem 0 1.2rem;
					line-height: 1.3333em;
					border-radius: $radius-general;

					&.orange {
						background-color: $color-orange-bg;
						color: $color-orange;
					}

					&.primary {
						background-color: $color-primary-bg;
						color: $color-primary;
					}

					&.placeholder {
						width: 12rem;
					}

					i {
						font-size: 3rem;
						margin-left: 1.7rem;
						opacity: .5;
					}

					@include media {
						padding: 0 1.5rem 0 1.1rem;
						font-size: 1.1rem;
						height: 5rem;

						i {
							margin-left: 1rem;
						}

						&:last-child {
							flex: 1 0 0;
						}
					}
				}

			.right-storestockbtn {
				margin-top: 2rem;
			}

			.right-emptymessage {
				margin-bottom: 2rem;
			}

			.right-controls {
				display: flex;
				flex-flow: row wrap;

				@include mediaMin {
					justify-content: flex-start;
				}
			}

				.controls-btn {
					@include font-semibold;
					background-color: $color-blue-bg;
					color: $color-green;
					margin-right: 1rem;
					padding: 0 1.1rem;
					height: 3rem;
					font-size: 1.4rem;
					display: inline-flex;
					align-items: center;
					transition: background-color $transition-hover ease, opacity $transition-hover ease;

					&:disabled {
						opacity: .5;
					}

					&.placeholder {
						width: 15rem;
					}

					&:last-child {
						margin-right: 0;
					}

					i {
						font-size: 1.6rem;
						margin-right: .8rem;
					}

					@include mediaMin {
						&:hover {
							background-color: darken($color-blue-bg, $darken-ratio);
						}
					}

					@include media {
						flex: 1 0 0;
						height: 10rem;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: flex-start;
						text-align: center;
						padding: 2rem 0 0;
						font-size: 1.4rem;

						&:last-child {
							margin-right: 0;
						}

						i {
							margin: 0 0 1rem;
						}
					}

				}

			.right-share {
				@include font-semibold;
				display: flex;
				flex-flow: row wrap;
				margin-top: 2rem;
				color: $color-text-blue-light;
				align-items: center;

				&.placeholder {
					width: 21rem;
					height: 1.7rem;
				}
				
				> * {
					+ * {
						margin-left: 1.2rem;
					}
				}
				
				a {
					transition: color $transition-hover ease;

					@include mediaMin {
						&:hover {
							color: $color-text-blue;
						}
					}
				}
			}
		}

		.info-sitefeatures {
			width: 100%;

			@include media {
				margin: 0 -2rem;
				width: calc(100% + 4rem);
			}
		}

		.info-bottom {
			width: 100%;

			&:last-child {
				margin-bottom: 4rem;
			}

			.bottom-tabs {

				@include mediaMin {
					.tabs-labels {
						&:before {
							display: none;
						}

						margin-bottom: 2.5rem;
					}
	
						.labels-innerwrap {
							justify-content: unset;
							background-color: $color-gray-dark;
							border-radius: $radius-general;
							overflow: hidden;
							padding: 0;
						}
	
						.labels-item {
							background-color: transparent;
							border: none;
							flex: 1 0 0;
						}

							.item-btn {
								width: 100%;
								border-radius: 0;
								height: 5rem;
								background: none;
								justify-content: center;
								color: $color-white;

								&:after {
									display: none;
								}

								&.active {
									background-color: $color-blue;
								}
							}
				}
			}

			// Mobile
			&.accordion {
				border: .1rem solid $color-gray-light;
				box-shadow: $shadow-big;
				border-radius: $radius-general;
				margin-top: 3rem;

				> li + li {
					border-top: .1rem solid $color-gray-light;
				}

				.collapser-btn {
					padding-left: 1.5rem;
					padding-right: 1.5rem;
				}

					.btn-icon {
						border: none;
						width: 1rem;
						height: 1rem;
					}

				.collapser-innerwrap {
					padding-left: 1.5rem;
					padding-right: 1.5rem;
				}
			}

		}

		.info-comments {
			.comments-sum {
				background-color: $color-bg;
				border-radius: $radius-big;
				padding: 2rem;
				margin-bottom: 2.5rem;
				display: flex;
				justify-content: space-between;
				color: $color-text-light;

				@include media {
					display: block;
				}
			}

				.sum-title {
					font-size: 1.4rem;
					display: block;
					margin-bottom: 1rem;
					@include media {
						margin-bottom: 2rem;
					}
				}

				.sum-rating {
					display: flex;
					align-items: center;
					justify-content: flex-start;

					@include media {
						margin-bottom: 2.5rem;
					}
				}

					.rating-score {
						font-size: 5rem;
						color: $color-text;
					}

					.rating-stars {
						margin: 0 1.5rem;
					}

			.comments-title {
				@include font-bold;
				font-size: 1.4rem;
				line-height: 2rem;
				margin-bottom: .5rem;
			}

			// .comments-list {}

				.list-comment {
					border-top: .1rem solid $color-gray-light;
					padding-top: 2rem;
					font-size: 1.3rem;

					+ .list-comment {
						margin-top: 2rem;
					}
				}

					.comment-info {
						display: flex;
						align-items: center;
						color: $color-text-lighter;
					}

						.info-date {
							width: 16.4rem;
							margin-left: 1rem;
						}

					.comment-message {
						// color: $color-text-light;
						margin-top: 1rem;
						max-width: 78rem;
					}
		}

		.info-featuredcomment {
			padding: 1.6rem 2rem;
			border: .1rem solid $color-gray;
			border-radius: $radius-general;
			margin: 2.5rem 0;

			.featuredcomment-sum {
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: .1rem solid $color-gray-light;
				width: calc(100% + 4rem);
				margin: 0 -2rem;
				padding: .5rem 1.5rem 2rem;
				margin-bottom: 1.5rem;
			}

				.sum-cta {
					text-decoration: underline;
				}

			.featuredcomment-head {
				display: flex;
				justify-content: space-between;
				font-size: 1.3rem;
				letter-spacing: .025em;
				color: $color-text-light;
				margin-bottom: 1rem;
			}

				.head-left {
					flex: 1 0 0;
					display: flex;
					justify-content: flex-start;
				}

			.featuredcomment-rating {
				width: 6.7rem;
				height: 1.1rem;
			}

			.featuredcomment-datetime {
				margin-left: .9rem;

				&.placeholder {
					width: 10.5rem;
					height: 1.8rem;
					display: inline-block;
				}
			}

			.featuredcomment-author {
				&.placeholder {
					width: 9.4rem;
					height: 1.4rem;
				}
			}

			.featuredcomment-text {
				line-height: 1.3em;
				&.placeholder {
					width: 100%;
					height: 3rem;
				}
			}

			.featuredcomment-cta {
				margin-top: 1rem;
				font-size: 1.3rem;
				text-decoration: underline;

				&.placeholder {
					width: 9rem;
					height: 1.3rem;
				}
			}
		}

		.info-properties {
			border: .1rem solid $color-gray-light;
			border-bottom: none;
			background-color: $color-bg-light;

			.properties-title {
				padding: 1.5rem 1rem 1.2rem;
			}

			.properties-table {
				border-top: .1rem solid $color-gray-light;
			}

			tbody {
				th, td {
					padding: 1rem 1rem;
				}

				th {
					width: 25%;
				}

				td {
					color: $color-text-light;
				}
			}
		}

		.info-campaigns {
			.campaigns-list {
				display: flex;
				width: calc(100% + 2rem);
				margin: -1rem;
				flex-wrap: wrap;
		
				@include media {
					display: block;
				}
			}
		
				.list-campaign {
					display: flex;
					padding: 1rem;
					width: 33.3333333%;
					width: calc(100% / 3);
		
					@include media {
						width: 100%;
					}
				}
		
					.campaign-button {
						display: flex;
						flex-direction: column;
						width: 100%;
						border: .1rem solid $color-gray-light;
						padding: 2rem;
						border-radius: $radius-general;
						box-shadow: $shadow-general;
						transition: background-color $transition-hover ease;
		
						@include mediaMin {
							&:hover {
								background-color: $color-bg-light;
							}
						}
					}
		
					.campaign-title {
						display: block;
						color: $color-pink;
						margin-bottom: 1rem;
					}
		
					.campaign-description {
						flex: 1 0 0;
						font-size: 1.4rem;
						line-height: 1.3em;
						color: $color-text-light;
						max-height: 3.9em;
						overflow: hidden;
					}
		
					.campaign-image {
						width: 100%;
						display: block;
						padding-bottom: 44%;
						margin-bottom: 2rem;
						background-color: $color-bg;
						border-radius: $radius-general;
					}
		
					.campaign-ctawrap {
						@include font-medium;
						display: flex;
						justify-content: flex-end;
						align-items: center;
						margin-top: 2rem;
		
						i {
							margin-left: 1rem;
						}
					}
		}

		.info-installments {
			@include media {
				.options-banks {
					width: calc(100% + 3rem);
					margin: 0 -1.5rem;
					padding-left: 1.5rem;
					padding-right: 1.5rem;
				}
			}
		}

		.info-description {	
			+ .info-properties {
				margin-top: 3rem;
			}
		}

		.info-suggestions {
			padding: 2rem 0;

			.suggestion-link {
				display: block;
				padding: 1.5rem 1.5rem;
				display: flex;
				justify-content: space-between;
				align-items: center;

				&:after {
					@include icon('arrow-right');
				}

				+ .suggestion-link {
					border-top: .1rem solid $color-gray-light;
				}
			}
		}

		.info-appcontrols {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 2;
			background-color: $color-white;
			padding: .5rem 2rem calc(.5rem + var(--safe-area-insets-bottom, env(safe-area-inset-bottom)));
			box-shadow: $shadow-wide;
			display: flex;
			justify-content: space-between;
			align-items: center;
			min-height: 6.5rem;
			
			.appcontrols-left {
				flex: 1 0 0;

				.left-labels {
					margin-bottom: .3rem;
					line-height: .8rem;
				}

					.labels-label {
						@include font-semibold;
						color: $color-primary;
						font-size: .8rem;
					}
			}

			.appcontrols-pricebox {
				font-size: 1.6rem;
			}


			.appcontrols-btns {
				display: flex;
				justify-content: center;
				align-items: center;
				> * + * {
					margin-left: 1rem;
				}
			}

				.btns-group {
					text-align: right;
				}

				.btns-stockbtn {
					font-size: 1.2rem;
					margin-top: .5rem;
				}
		}
	}

	&.detail-popularproducts {
		// content-visibility: auto;
		padding-bottom: 2rem;
	}

	&.detail-recentproducts {
		// content-visibility: auto;
		padding-bottom: 2rem;
	}

	&.detail-category-top-products {
		// content-visibility: auto;
		padding: 0;
	}
}