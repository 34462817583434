@import "core/index.scss";
.product-installment-options {
	.options-installments {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		margin-top: 2.5rem;
		margin-bottom: -2rem;
	}

		.installments-group {
			border: .1rem solid $color-gray-light;
			width: calc(33.3333333333% - 1rem);
			margin-bottom: 2rem;

			@include media {
				width: 100%;
			}
		}

			.group-head {
				padding: 2rem;
				border-bottom: .1rem solid $color-gray-light;
				display: flex;
				justify-content: flex-start;
				align-items: center;
			}

				.head-logo {
					height: 3.6rem;
				}

				.head-title {
					margin-left: 1.5rem;
					font-size: 2rem;
				}

			.group-opts {
				padding: 0 2rem;
			}

				.opts-opt {
					display: flex;
					justify-content: space-between;
					padding: 1rem 0;

					+ .opts-opt {
						border-top: .1rem solid $color-gray-light;
					}
				}

					.opt-promo {
						@include font-regular;
						display: inline-block;
						font-size: 1.2rem;
						padding: .2rem .5rem;
						line-height: 1.2rem;
						background-color: $color-orange;
						margin-left: .5rem;
						color: $color-white;
						border-radius: $radius-small;
						vertical-align: text-bottom
					}
}