@import "core/index.scss";
.rating-box {
	position: relative;
	display: inline-block;
	display: inline-flex;
	justify-content: flex-start;

	&.big {
		.box-star {
			width: 2rem;
			height: 2rem;
		}
	}

	&:not(.input) {
		.box-star {
			cursor: auto;
		}
	}

	.box-star {
		width: 1.1rem;
		height: 1.1rem;
		background-image: url('/assets/images/product/rating-star-empty.svg');

		&.full {
			background-image: url('/assets/images/product/rating-star-full.svg');
		}

		+ .box-star {
			margin-left: .2rem;
		}
	}

		.star-ratio {
			display: block;
			height: 100%;
			background-size: auto 100%;
			background-image: url('/assets/images/product/rating-star-full.svg');
		}
}